(function () {
    var Alignment = {
        hasSomeParentTheClass: function (element, classname) {
            try {
                if (element && element.className && element.className.split(' ').indexOf(classname) >= 0) {
                    return true;
                }

                return element.parentNode && Alignment.hasSomeParentTheClass(element.parentNode, classname);
            } catch (e) {
                return false;
            }
        },

        resize: function () {
            var topRow = [];
            var allElements = {};

            var remainingElements = Array.prototype.slice.call(document.querySelectorAll('.align-height'))
            .filter(function (filterElement) {
                return Object.prototype.toString.call(filterElement) === '[object HTMLDivElement]';
            })
            .filter(function (filterElement) {
                return !Alignment.hasSomeParentTheClass(filterElement, 'columnmarker');
            });

            do {
                allElements = Alignment.splitByTopRow(remainingElements);
                topRow = allElements.topRow;
                remainingElements = allElements.remaining;
                Alignment.alignRow(topRow);
                Alignment.alignButtonInProductToBottom(topRow);
            } while (remainingElements.length > 0);
        },

        alignRow: function (row) {
            var maxHeight = 0;

            // Reset height
            row.forEach(function (element) {
                element.style.height = 'auto';
            });

            // Find the highest element
            row.forEach(function (element) {
                var height = $(element).outerHeight(true);
                if (height > maxHeight) {
                    maxHeight = height;
                }
            });

            // Set height
            row.forEach(function (element) {
                element.style.height = maxHeight + 'px';
            });
        },

        splitByTopRow: function (elementsArray) {
            // Shallow copy
            var elements = elementsArray.slice(0);

            var offset = Alignment.getOffset(elements[0]);

            var predicate = function (element) {
                return Alignment.getOffset(element) === offset;
            };

            var topRow = Alignment.takeWhile(elements, predicate);

            topRow.forEach(function (element) {
                elements.splice(elements.indexOf(element), 1);
            });

            return {
                topRow: topRow,
                remaining: elements
            };
        },

        getOffset: function (element) {
            var offset = $(element).offset();
            return offset && offset.top;
        },

        alignButtonInProductToBottom: function (row) {
            var productBoxesBtn = [];

            row.map(function (element) {
                Array.prototype.slice.call(element.querySelectorAll('.product-box-btn.js-align-bottom'))
                .filter(function (prototypeElement) { 
				return Object.prototype.toString.call(prototypeElement) === '[object HTMLDivElement]' }).forEach(function (productBoxElement) {
                    productBoxesBtn.push(productBoxElement);
                });
            });

            var $productBoxes = $(productBoxesBtn).closest('.product-box');

            $productBoxes.each(function () {
                var $pushbox = $(this);
                var totalHeight = $pushbox.outerHeight();
               //var btnHeight = $pushbox.find('.component-btn').outerHeight();
                var standingProductBox = $pushbox.height() > $pushbox.width();
				const pushboxHeight = 100;
                var margin = totalHeight - pushboxHeight;
                var isMobile = window.matchMedia("only screen and (max-width: 1024px)");

                if (standingProductBox || (isMobile.matches && !standingProductBox)) {
					const productBoxMargin = 127;
                    margin = margin - productBoxMargin;
                }

                $pushbox
                .find('.js-align-bottom')
                .css({
                   top: Math.max(0, margin)
               });
            });
        },

        // From http://sufflavus.github.io/JS-Tips-Take-While
        takeWhile: function (source, predicate) {
            var stopIndex = source.length;

            source.some(function (n, index) {
                return predicate(n, index) ? false : ((stopIndex = index), true);
            });

            return source.slice(0, stopIndex);
        }
    };

    var docWidth = $(document).width();

    $('.tab').click(Alignment.resize);
    $(window).load(Alignment.resize);
    $(window).resize(function () {
        var newWidth = $(document).width();

        if (docWidth !== newWidth) {
            Alignment.resize();
            docWidth = newWidth;
        }
    });
})();